import request from '@/utils/request'

export function pageInit() {
    return request({
        url:'/api/MailTemplate/pageInit',
        method:'get',
    })
}
export function listPage(params) {
    return request({
        url:'/api/MailTemplate/listPage',
        params,
        method:'get'
    })
}
export function del(id) {
    return request({
        url:`/api/MailTemplate/${id}`,
        method:'delete'
    })
}
export function delBatch(data) {
    return request({
        url:`/api/MailTemplate/batchDelete`,
        method:'delete',
        data
    })
}

export function addInit() {
    return request({
        url:'/api/MailTemplate/addInit',
        method:'get'
    });
}

export function mailTemplate(data) {
    return request({
        url:'/api/MailTemplate',
        method:'post',
        data
    });
}
export function updateInit(id) {
    return request({
        url:`/api/MailTemplate/updateInit/${id}`,
        method:'get',
    });
}

export function updateTemplate(data) {
    return request({
        url:`/api/MailTemplate`,
        method:'put',
        data
    });
}


